import { BellPlus } from 'lucide-react';

export const EmptyNotification = () => {
  return (
    <div className="flex flex-col items-center justify-center p-12 gap-6 w-full m-auto h-full">
      <BellPlus className="h-28 w-28 -mt-4" style={{ stroke: '#909090', strokeWidth: 1 }} />
      <p className="text-center text-sm notification-empty-text" style={{ color: '#606060' }}>
        Nothing to see here right now—you&apos;re all caught up!
      </p>
    </div>
  );
};
