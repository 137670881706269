import './notification-wrapper.css';
import { INotification } from '@notification/service';
import { EmptyNotification } from './empty-notification';
import { NotificationList } from './notification-list';
import React from 'react';

interface Props {
  notifications: INotification[];
  onNextPage: () => void;
  hasMorePages: boolean;
  isSuccess: boolean;
  children: React.ReactNode;
  onUpdateNotification: (notification: Partial<INotification>) => void;
}
export const NotificationWrapper = (props: Props) => {
  return (
    <div className="flex flex-col overflow-hidden">
      {props.children}
      <div className="h-full w-full flex flex-auto notification-wrapper-content-area">
        {props.notifications.length > 0 && (
          <NotificationList
            notifications={props?.notifications || []}
            onNextPage={props.onNextPage}
            hasMorePages={props.hasMorePages}
            onUpdateNotification={props.onUpdateNotification}
          />
        )}
        {props.isSuccess && props.notifications.length === 0 && <EmptyNotification />}
      </div>
    </div>
  );
};
