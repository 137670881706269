import { INotification } from '@notification/service';
import { Avatar, StatusDot } from '@ui/components';
import { NotificationIconTag } from './notification-icon-tag';
import { useRouter } from 'next/navigation';
import { useNotificationPanelState } from './notification-panel-context';
import trophy3D from '@ui/assets/images/placeholder-prize-badge.svg';
import Image from 'next/image';

interface Props {
  notification: INotification;
  onUpdateNotification: (notification: Partial<INotification>) => void;
}
export const NotificationItem = (props: Props) => {
  const { closeNotificationPanelState } = useNotificationPanelState();
  const { push } = useRouter();
  const onClick = () => {
    if (props.notification?.miscellaneous?.route) {
      push(props.notification?.miscellaneous?.route);
    }
    closeNotificationPanelState();
    props.onUpdateNotification({
      id: props.notification.id,
      user_id: props.notification.user_id,
      read: true,
    });
  };

  return (
    <div
      onClick={onClick}
      className="flex items-center gap-2 hover:bg-input px-3 py-2 cursor-pointer relative">
      {!props.notification.read && (
        <span className="absolute" style={{ left: '6px' }}>
          <StatusDot className="bg-primary h-1 w-1" />
        </span>
      )}
      <div className="relative flex flex-row items-end">
        {props.notification.miscellaneous?.createdFrom !== 'challenge_prize_unlocked' && (
          <>
            <Avatar src={props.notification?.miscellaneous?.mentionedBy?.profileURL} size="sm" />
            <NotificationIconTag type={props.notification.miscellaneous?.createdFrom} />
          </>
        )}
        {props.notification.miscellaneous?.createdFrom === 'challenge_prize_unlocked' && (
          <Image src={trophy3D} alt="trophy3D" width={52} height={52} />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-sm">
          {props.notification?.name && (
            <span className="font-medium mr-1">{props.notification?.name}</span>
          )}
          {props.notification?.miscellaneous?.mentionedBy?.userName && (
            <span className="font-medium mr-1">
              {props.notification?.miscellaneous?.mentionedBy?.userName}
            </span>
          )}
          {props.notification?.miscellaneous?.suffixLabel && (
            <span>{props.notification?.miscellaneous?.suffixLabel}</span>
          )}
        </div>
        <div className="text-sm">
          <span className="font-medium mr-1 bg-input text-primary px-2 py-1 rounded-lg">
            {props.notification?.miscellaneous?.routeLabel}
          </span>
        </div>
      </div>
    </div>
  );
};
