import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NotificationPanelContextType {
  isOpen: boolean;
  openNotificationPanelState: () => void;
  closeNotificationPanelState: () => void;
}

const NotificationPanelContext = createContext<NotificationPanelContextType | undefined>(undefined);

export const useNotificationPanelState = () => {
  const context = useContext(NotificationPanelContext);
  if (!context) {
    throw new Error(
      'useNotificationPanelState must be used within a NotificationPanelStateProvider',
    );
  }
  return context;
};

interface NotificationPanelStateProviderProps {
  children: ReactNode;
}

export const NotificationPanelStateProvider = ({
  children,
}: NotificationPanelStateProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const openNotificationPanelState = () => setIsOpen(true);
  const closeNotificationPanelState = () => setIsOpen(false);

  return (
    <NotificationPanelContext.Provider
      value={{ isOpen, openNotificationPanelState, closeNotificationPanelState }}>
      {children}
    </NotificationPanelContext.Provider>
  );
};
