import { useId } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { INotification } from '../service/typings';
import { LeaderboardItemLoader } from '@ui/components/organisms/leaderboard/components/leaderboard-item';
import { NotificationItem } from './notification-item';

interface Props {
  notifications: INotification[];
  onNextPage: () => void;
  hasMorePages: boolean;
  onUpdateNotification: (notification: Partial<INotification>) => void;
}
export const NotificationList = (props: Props) => {
  const id = useId();

  return (
    <div id={id} className="w-full overflow-y-auto notification-infinite-scroll-area">
      <InfiniteScroll
        dataLength={props.notifications.length}
        next={props.onNextPage}
        hasMore={props.hasMorePages}
        scrollableTarget={id}
        loader={<LeaderboardItemLoader />}>
        {props.notifications.map((item) => (
          <NotificationItem
            key={item.id}
            notification={item}
            onUpdateNotification={props.onUpdateNotification}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
